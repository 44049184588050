import React from "react"
import { UserForm } from "../components/UserForm/UserForm"
import { GoogleMaps } from "../components/GoogleMaps/GoogleMaps"
import { Layout } from "../components/Layout/Layout"
import { graphql } from "gatsby"
import { PageTitle } from "../components/PageTitle/PageTitle"

interface ApproachProsp {
  data: {
    cms: {
      page: {
        approachFields: {
          subHeading: string
          userHeading: string
        }
        title: string
      }
    }
  }
}

export const Approach: React.FC<ApproachProsp> = ({ data }) => {
  const dataVariable = data.cms.page.approachFields
  return (
    <Layout withPadding>
      <div className="mx-auto flex w-10/12 flex-col items-center lg:w-full lg:px-36">
        <PageTitle marginBottom="mb-18">{data.cms.page.title}</PageTitle>
        <GoogleMaps />
        <PageTitle>{dataVariable.subHeading}</PageTitle>
        <UserForm heading={dataVariable.userHeading} />
      </div>
    </Layout>
  )
}
export default Approach

export const query = graphql`
  {
    cms {
      page(id: "/approach", idType: URI) {
        approachFields {
          subHeading
          userHeading
        }
        title
      }
    }
  }
`
