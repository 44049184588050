import React, { useCallback, useState } from "react"

const firstRandNum = Math.floor(Math.random() * 6) + 1
const secondRandNum = Math.floor(Math.random() * 6) + 1

interface UserFormProps {
  heading?: string
}

const encode = (data: Record<string, string>) => {
  return Object.entries(data)
    .map(([key, value]) => {
      return encodeURIComponent(key) + "=" + encodeURIComponent(value)
    })
    .join("&")
}
export const UserForm: React.FC<UserFormProps> = ({ heading }) => {
  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [nachricht, setNachricht] = useState("")
  const [hover, setHover] = useState(false)
  const [error, setError] = useState(false)
  const [userInput, setUserInput] = useState("")

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value)
  }
  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value)
  }

  const handleMessageChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>,
  ) => {
    setNachricht(event.target.value)
  }

  const getLabel = (
    label: string,
    change: (event: React.ChangeEvent<HTMLInputElement>) => void,
    userInput: string,
    labelName: string,
  ) => {
    return (
      <label className="flex w-full text-left md:w-1/2 md:flex-wrap">
        <input
          className={`flex w-full p-5 placeholder-headings placeholder-opacity-50 ${
            error ? "border border-red-500" : ""
          }`}
          onChange={change}
          name={labelName}
          value={userInput}
          type="text"
          placeholder={label}
        />
      </label>
    )
  }

  const handleCaptchaChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUserInput(event.target.value)
  }

  const getCaptchaLabel = () => {
    return (
      <input
        className={`ml-2 h-14 w-14 bg-comments-textare text-center ${
          error ? "border border-red-500" : ""
        }`}
        onChange={handleCaptchaChange}
        value={userInput}
      />
    )
  }

  const getBiggerLabel = (
    label: string,
    message: (event: React.ChangeEvent<HTMLTextAreaElement>) => void,
    change: string,
    labelName: string,
  ) => {
    return (
      <label className="flex w-full text-left">
        <textarea
          name={labelName}
          onChange={message}
          rows={6}
          className={`flex-grow bg-comments-textare p-5 placeholder-headings placeholder-opacity-50 ${
            error ? "border border-red-500" : ""
          }`}
          value={change}
          placeholder={label}
        />
      </label>
    )
  }
  const handleClick = (event: React.MouseEvent) => {
    event.preventDefault()
    const isIncorrectCaptcha =
      firstRandNum + secondRandNum !== parseFloat(userInput)
    if (
      name.length === 0 ||
      email.length === 0 ||
      nachricht.length === 0 ||
      isIncorrectCaptcha
    ) {
      setError(true)
      return
    }
    setError(false)
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({ "form-name": "contact", name, email, nachricht }),
    })
    setName("")
    setEmail("")
    setNachricht("")
    return
  }

  const enterHover = useCallback(() => {
    return setHover(true)
  }, [])
  const leaveHover = useCallback(() => {
    return setHover(false)
  }, [])
  return (
    <form
      name="contact"
      method="post"
      data-netlify={true}
      className="flex w-full flex-col flex-wrap items-center p-2 font-titilliumWeb md:flex-row"
    >
      {heading && (
        <h2
          className={`mb-10 flex w-full flex-col items-center font-playfairDisplay text-3xl font-extralight text-headings opacity-70`}
        >
          {heading}
        </h2>
      )}
      {error && (
        <div className="mb-16 flex w-full flex-col space-y-2 text-headings opacity-70">
          <span>Bitte füllen Sie die folgenden Felder aus:</span>
          <ul className="list-inside list-disc space-y-2">
            <li>Name</li>
            <li>E-Mail-Adresse</li>
            <li>Nachricht</li>
            <li>Captcha</li>
          </ul>
        </div>
      )}
      <div className="mb-6 flex w-full flex-grow flex-col space-y-6 md:mb-8 md:flex-row md:space-y-0 md:space-x-8">
        {getLabel("Name", handleNameChange, name, "name")}
        {getLabel("E-Mail-Adresse", handleEmailChange, email, "email")}
      </div>
      {getBiggerLabel("Nachricht", handleMessageChange, nachricht, "nachricht")}
      <div className="mt-6 flex w-full items-center justify-end">
        <div className="flex flex-row items-center justify-end">
          <span className="tracking-wider">
            {firstRandNum} + {secondRandNum} ={getCaptchaLabel()}
          </span>
        </div>

        <button
          onClick={handleClick}
          onMouseEnter={enterHover}
          type="submit"
          onMouseLeave={leaveHover}
          className={`mt-2 ml-4 flex items-center rounded-md border-2 border-captchaSendButton px-4 py-3 font-navigation text-2xl text-captchaSendButton duration-300 ease-in-out hover:border-transparent hover:bg-glossy-200 ${
            hover ? "w-36" : "w-30"
          } focus:outline-none`}
          style={{
            transitionProperty: "width, background-color, border-color",
          }}
        >
          Senden
          <span
            style={{ transition: "width" }}
            className={`transform font-modules text-4xl leading-none transition-all duration-300 ease-in-out ${
              hover
                ? "w-full translate-x-0 opacity-100"
                : "w-0 -translate-x-4 opacity-0"
            }`}
          >
            5
          </span>
        </button>
      </div>
    </form>
  )
}
