import React from "react"
import { GoogleMap, Marker, useLoadScript } from "@react-google-maps/api"
import { getCookieConsentValue } from "react-cookie-consent"
import { cookie, possibleCookieValues } from "../CookieBanner/CookieBanner"

const ApiKey = "AIzaSyC5sdzlFS1HUFG69flSaTyMiruI6UAd_1o"

const containerStyle = {
  width: "100%",
  height: "400px",
}

const position = {
  lat: 52.39234,
  lng: 9.74839,
}

export const GoogleMaps: React.FC = () => {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: ApiKey,
  })

  if (
    getCookieConsentValue(cookie.VITALIST) !== possibleCookieValues.accepted
  ) {
    return (
      <div className="flex flex-col items-center">
        Sie habe das Verwenden von Cookies nicht akzeptiert, leider können wir
        Ihnen aufgrund dessen keine Karte von Google Maps anzeigen.
        <br />
        Unsere Adresse lautet:
        <br />
        Waldstraße 28, 30163 Hannover
      </div>
    )
  }

  return isLoaded ? (
    <div className="mb-44 flex w-full justify-center">
      <GoogleMap mapContainerStyle={containerStyle} center={position} zoom={18}>
        <Marker position={position} />
      </GoogleMap>
    </div>
  ) : (
    <div className="flex flex-col items-center">Lädt</div>
  )
}
